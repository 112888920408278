import './style.scss'
import React from 'react'
import Img from 'gatsby-image'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

export default function ISInfo({ whats_next_image, work_image }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={io} id="is-info" className="master-wrap grid-12 c3 padd-4 relative">
      <div className="bg bg-desktop">
        <Img fluid={whats_next_image.localFile.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover' }}
          objectPosition='50% 50%'
          className=''
					alt=''
        />
      </div>
      
      <Intro visible={ioInView} in={{ fadeLeft: 500 }} delayIn={500} mounted={true} stay={true} className='span-12'>
        <h2 className='h1 blue span-12'>What's Next?</h2>
      </Intro>

      <Intro visible={ioInView} in={{ fadeLeft: 500 }} delayIn={500} mounted={true} stay={true} className='span-6 span-12-mobile '>

        <div className='content left'>
          <h6>Applying for F-1 Visa</h6>
          <p className='blue'><strong>Obtaining an F-1 Student Visa is a crucial step in your journey to pursue studies in the U.S. It is essential to familiarize yourself with the visa application process and seek guidance from your immigration advisor or the University’s Designated School Official (DSO) before submitting your application.</strong></p>
          <p className='blue'><strong>Please review the <a href="https://travel.state.gov/content/travel/en/us-visas/study/student-visa.html" target='_blank' className='underscore'>F-1 Student Guide here</a> and familiarize yourself with the application process.</strong></p>
          <ol className='blue'>
            <li>Complete the Online Nonimmigrant Visa Application, <a href="https://ceac.state.gov/GenNIV/Default.aspx" target='_blank' className='underscore'>Form DS-160</a>. And pay the $185 visa application fee. </li>
            <li>Complete and pay the <a href="https://www.fmjfee.com/i901fee/index.html" target='_blank' className='underscore'>I-901 SEVIS Fee.</a> The fee is a one-time payment of $350</li>
            <li>Schedule an interview at your local U.S. Embassy or Consulate. You can find information on your local Embassy or Consulate by following <a href="https://www.usembassy.gov/" target='_blank' className='underscore'>this link</a>. </li>
          </ol>
        </div>

      </Intro>

      <Intro visible={ioInView} in={{ fadeRight: 500 }} delayIn={500} mounted={true} stay={true} className='span-6 span-12-mobile'>
        <div className='content border-left'>
          <h6>Once Your visa has been approved:</h6>
          <ol className='blue'>
            <li><strong>Settle your tuition fees</strong> for the first semester of your program in accordance with your student financial plan. If you have any questions regarding the payment process, feel free to consult your admissions advisor.</li>

            <li>Send us a <strong>copy of your F-1 Visa</strong>, we will review the original once you enter the U.S. </li>

            <li><strong>Prepare your entry documents for the U.S.</strong> In a separate folder, organize all the academic documents you used for university admission and your visa interview, including a printed I-20 form. These documents may be requested again by an immigration officer at the port of entry.</li>

            <li><strong>Register for class upon arrival to the country</strong>, international students are required to report to the University on the first day of the academic calendar as per their class schedule. This is to ensure timely registration for classes and visa compliance. Please bring along all your academic documents, including your I-20 form, and DSO will make copies for record-keeping purposes. If you are unable to attend on the first day, kindly inform our DSO at your earliest convenience.</li>
            <p className='blue'>Questions? E-mail us <a href="mailto:international@mru.edu" target='_blank' className='underscore'>international@mru.edu</a> </p>

          </ol>

        </div>
      </Intro>
    </div>
  )
}