import './style.scss'
import React from 'react'
import Img from 'gatsby-image'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

// Slider settings
const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  pauseOnFocus: true,
  autoplay: false,
  autoplaySpeed: 8000,
  slidesToShow: 3,
  slidesToScroll: 1,
  accessibility: false,
  fade: false,
  focusOnSelect: true,
  adaptiveHeight: false,
  centerMode: true,
  variableWidth: true
}


export default function ISGlimpse({ data }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const slideMap = data.glimpse_of_us_slider?.map((slide, index) => {
    return (
      <div key={index} className="slide">
        <Img fluid={slide.slider_image.localFile.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover' }}
          objectPosition='50% 50%'
          className=''
					alt={`Slide ${index}`}
        />
        <div className='text-center fs-85' style={{ padding: '.5rem' }} dangerouslySetInnerHTML={{ __html: slide?.description }}></div>
      </div>
    )
  })

  return (
    <div id="is-glimpse" className="c5 t">
      <div className="bg">
        <Img fluid={data.slider_backdrop_image.localFile.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover' }}
          objectPosition='50% 50%'
          className=''
					alt=''
        />
      </div>
      <div className="slider-wrapper">
        <div className="slider">
          <Slider {...sliderSettings}>
            {slideMap}
          </Slider>
        </div>
      </div>
      <div ref={io} className="wrap">
        <div className="title">
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={400} mounted={true} stay={true}>
            <h2>A glimpse of US<br />&amp; Our City</h2>
          </Intro>
        </div>
        <div className="copy">
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={400} mounted={true} stay={true}>
            <p>Miami is a vibrant and diverse city known for its unique blend of cultures, beautiful beaches, and a dynamic urban lifestyle. It boasts a tropical climate, making it a year-round destination for all and a amazing place to live.</p>
            <p>MRU's curates year-round events, where you will be able to make create close bonds with your fellow students and with the city we call home. We look forward to sharing this experience with you.</p>
          </Intro>
        </div>
      </div>
    </div>
  )
}